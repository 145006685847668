.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Media Query Laptops------------------start---------- */
@media only screen and (min-width: 577px), (max-width: 1199px) {

  /* Decor Details Css */

  .decorbody {
    width: 100%;
    padding-left: 7%;
    padding-right: 9%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .decormainimg {
    width: 100%;
    height: 100%;
    margin-right: 4%;
    margin-bottom: 25px;
  }

  .decormainimg2 {
    margin: 10px auto;
  }

  .decormainimg3 {
    width: 100%;
    height: 100%;
  }

  .decorsubimg {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }



  .decortextbody {
    width: 100%;
    height: 100%;
    padding-top: 3%;
  }

  .decorheadmain {
    font-size: 22px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmaindiv {
    display: flex;
    flex-direction: row;
  }

  .decorsubheadmain {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmain2 {
    margin-left: 8px;
    font-size: 17px;
    font-weight: 500;
    color: #404041;
  }

  .decortext {
    font-size: 15px;
    font-weight: 400;
    color: #404041;
    line-height: 20px;
  }

  .decorsubheadmain3 {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .decorbrandlogosbody, .decorbrands {
    width: 50%;
    display: flex;
    flex-direction: row;
    z-index: 1000 !important;
  }

 .decorbrands {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .decorbrandlogos {
    width: 10%;
    margin: auto 5px auto 20px;
  }

   .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem !important;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .thirdhead {
    height: 100%;
    width: 6% !important;
  }



  /* Decor Detail Css Ends */





  /* Contact us Css Starts */

  .contacttext {
    font-size: 17px;
    font-weight: normal;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
  }

  .contactbody {
    min-height: 656px;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding-top: 1.8%;
  }

  .contactbody2 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  /* Contact us Css Ends */

  /* Tech Spec Css Starts */

  .subheadmain {
    font-size: 22px;
    font-weight: bold;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .imgtop {
    margin-left: 10%;
    margin-right: 0%;
    height: 14%;
    width: 85%;
  }

  .imgbottom {
    margin-left: 10%;
    width: 85.5%;
    height: 14%;
  }

  .headbottom {
    font-size: 22px;
    font-weight: bold;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
    margin-top: 50px;
  }

  /* Tech Spec Css Ends */

  /* Our Design Studio Css  */

  .Submainhead {
    font-size: 27px !important;
    font-weight: bold;
    color: #4b4b4b;
    text-align: center;
    margin-top: 65px !important;
    margin-bottom: 44px;
  }

  .Imgheight {
    min-height: 607px;
  }

  .imgsize {
    width: 100%;
    height: 20.1%;
  }

  .upfooter {
    width: 100%;
    padding-left: 26%;
    height: 152%;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }

  .upfootertext {
    height: 100%;
    padding-right: 5%;
  }

  .upfootertext1 {
    font-size: 22px;
    font-weight: 900;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
  }

  .upfootertext2 {
    font-size: 17px;
    font-weight: 500;
    font-family: "CenturyGothic";
    color: #404041;
    text-align: center;
  }

  .upfooternum {
    font-size: 17px;
    font-weight: 500;
    font-family: "AvenirRoman";
    color: #404041;
    text-align: center;
    margin-top: 10px;
  }

  .imgupfooter {
    margin-left: 49%;
    width: 20px;
    height: 25px;
    margin-right: 20px;
  }

  .imgupfooter1 {
    margin-top: 3%;
    width: 20px;
    height: 172px;
  }

  /* Our Design Studio Css  */

  /* DesignDiaries Css Starts */

  .mainheaddd {
    font-size: 22px;
    font-weight: bold;
    color: #4b4b4b;
    text-align: center;
    margin-top: 49px;
    margin-bottom: 25px;
  }

  /* .sec1dd {
    height: 110px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  } */

  .img1div {
    width: 43.3%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 20px;
  }

  .img2div {
    width: 23.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 20px;
  }

  .img3div {
    width: 28%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s2img2div {
    width: 28%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 20px;
  }

  .s2img3div {
    width: 43.3%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s3img2div {
    width: 53.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s4img1div {
    width: 38.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .s4img2div {
    width: 19.7%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .s4img3div {
    width: 36.6%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s5img1div {
    width: 53.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .s6img3div {
    width: 38.8%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* DesignDiaries Css Ends */

  .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 40rem !important;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .thirdhead {
    height: 100%;
    width: 98% !important;
  }
  .me-auto {
    margin-right: auto !important;
    display: flex !important;
  }
   /* Header Css Starts */
  
   .headerbody{
    height: 23px;
    width: 100%;
    background-color:#dae305 ;
  }
  
  .headernavbar{
  width: 100% !important;
  height: 135px !important;
  background-color:#fff !important;
  padding-left: 4% !important;
  padding-right: 4% !important; 
  }
  
  .headerboxshadow {
  box-shadow: -7px 7px 45px 0px rgba(0, 0, 0, 0.1);
  
  }
  
  .navbody{
  margin-top: auto;
  margin-bottom: auto;
  display: flex !important;
  justify-content: center;
  width: 95%;
  height: 82px !important;
  padding-left: 0px !important;
  }
  
  .navstack{
  padding: 10px;
  display: flex;
  justify-content: right;
  margin-top: 15px;
  }
  
  .navdropdown{
  background-color:#fff;
  border: 0px solid transparent;
  width: 40px;
  height: 28px;
  }
  
  .navdropdownimg{
  width: 29px;
  height: 15px;
  }
  
  .bottomdiv{
  padding: 10px;
  margin-top: auto;
  height: 60px;
  display: flex;
  justify-content: right;
  }
  
  /* Header Css Ends */

  .aboutbodyresp {
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-left: 8%;
    padding-right: 10%;
    padding-top: 1.8%;
  }

  .aboutimgresp {
    margin-left: 25%;
    margin-top: 10%;
    margin-bottom: 10%;
    width: 248px;
    height: 425px;
  }

  .headmainresp {
    font-size: 22px;
    font-weight: bold;
    font-family: "AvenirRoman";
    color: #4b4b4b;
  }

  .respabout {
    font-weight: normal;
    font-family: "CenturyGothic";
    color: #404041;
    line-height: 20px;
  }
}

/* Media Query Phone------------------start---------- */

@media only screen and (min-width: 300px), (max-width: 576px) {

  /* Decor Details Css */

  .decorbody {
    width: 100%;
    padding-left: 7%;
    padding-right: 9%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .decormainimg {
    width: 100%;
    height: 100%;
    margin-right: 4%;
    margin-bottom: 25px;
  }

  .decormainimg2 {
    margin: 10px auto;
  }

  .decormainimg3 {
    width: 100%;
    height: 100%;
  }

  .decorsubimg {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  .decortextbody {
    width: 100%;
    height: 100%;
    padding-top: 3%;
  }

  .decorheadmain {
    font-size: 22px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmaindiv {
    display: flex;
    flex-direction: row;
  }

  .decorsubheadmain {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmain2 {
    margin-left: 8px;
    font-size: 17px;
    font-weight: 500;
    color: #404041;
  }

  .decortext {
    font-size: 15px;
    font-weight: 400;
    color: #404041;
    line-height: 20px;
  }

  .decorsubheadmain3 {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .decorbrandlogosbody {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .decorbrandlogos {
    width: 10%;
    margin: auto 5px auto 20px;
  }

  Decor .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem !important;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .thirdhead {
    height: 100%;
    width: 6% !important;
  }



  /* Decor Detail Css Ends */













  /* Our Design Studio Css  */

  .Submainhead {
    font-size: 22px;
    font-weight: bold;
    color: #4b4b4b;
    text-align: center;
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .Imgheight {
    min-height: 607px;
  }

  .imgsize {
    width: 100%;
    height: 20.1%;
  }

  .upfooter {
    width: 100%;
    padding-left: 13%;
    height: 152%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
  }

  .upfootertext {
    height: 100%;
    padding-right: 17%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .upfootertext1 {
    font-size: 22px;
    font-weight: 900;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
  }

  .upfootertext2 {
    font-size: 15px;
    font-weight: 500;
    font-family: "CenturyGothic";
    color: #404041;
    text-align: center;
  }

  .upfooternum {
    font-size: 17px;
    font-weight: 500;
    font-family: "AvenirRoman";
    color: #404041;
    text-align: center;
    margin-top: 10px;
  }

  .imgupfooter {
    margin-left: 49%;
    width: 20px;
    height: 25px;
    margin-right: 20px;
    display: none;
  }

  .imgupfooter1 {
    margin-top: 3%;
    width: 20px;
    height: 172px;
    display: none;
  }

  /* Our Design Studio Css  */

  /* Footer Css Starts */

  .topdiv {
    height: 23px;
    width: 100%;
    background-color: #dae305;
    margin-top: 50px;
  }

  .footercontact {
    border-right: 1px solid transparent;
  }

  .footermd {
    width: "100%";
    background-color: #414042;
  }

  .footersmd {
    width: 100%;
    padding: 20px;
    display: flex;
    padding-top: 34px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .foh5 {
    color: #fff;
    border-right: 2px solid rgba(218, 227, 5, 1);
    border-left: 2px solid rgba(218, 227, 5, 1);
    text-align: center;
    margin-top: 4.5px;
    font-family: "AvenirRoman";
    text-decoration: none !important;
    padding-right: 50px;
    padding-left: 50px;
  }

  .foh6 {
    padding-right: 26px;
    padding-left: 26px;
  }

  .foh7 {
    padding-right: 60px;
    padding-left: 60px;
  }

  .foh8 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .foh9 {
    padding-right: 54px;
    padding-left: 54px;
  }

  .foh55 {
    padding-right: 13px;
    padding-left: 13px;
  }

  /* Footer Css End */

  /* DesignDiaries Css Starts */

  .mainheaddd {
    font-size: 22px;
    font-weight: bold;
    color: #4b4b4b;
    text-align: center;
    margin-top: 49px;
    margin-bottom: 25px;
  }

  /* .sec1dd {
    height: 110px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  } */

  .img1div {
    width: 43.3%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .img2div {
    width: 23.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .img3div {
    width: 28%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s2img2div {
    width: 28%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .s2img3div {
    width: 43.3%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s3img2div {
    width: 53.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s4img1div {
    width: 38.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .s4img2div {
    width: 19.7%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .s4img3div {
    width: 36.6%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s5img1div {
    width: 53.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }

  .s6img3div {
    width: 38.8%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* DesignDiaries Css Ends */

  .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 60% !important;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .thirdhead {
    height: 100%;
    width: 100% !important;
  }
  /* Header Css Starts */

  .headerbody {
    height: 23px;
    width: 100%;
    background-color: #dae305;
  }

  .headernavbar {
    width: 100%;
    height: 135px;
    background-color: #fff;
    padding-left: 4%;
    padding-right: 4%;
  }

  .headerboxshadow {
    box-shadow: -7px 7px 45px 0px rgba(0, 0, 0, 0.1);
  }

  .me-auto {
    margin-right: auto !important;
    display: none !important;
  }

  .navbody {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
    justify-content: center;
    width: 60%;
    height: 135px;
    padding-left: 120px;
  }

  .navstack {
    padding: 10px;
    display: flex;
    justify-content: right;
    margin-top: 15px;
  }

  .navdropdown {
    background-color: #fff;
    border: 0px solid transparent;
    width: 40px;
    height: 28px;
  }

  .navdropdownimg {
    width: 29px;
    height: 15px;
  }

  .bottomdiv {
    padding: 10px;
    margin-top: auto;
    height: 60px;
    display: flex;
    justify-content: right;
  }

  /* Header Css Ends */

  .aboutimgrespmob {
    margin-left: 0px !important;
    margin-top: 10%;
    margin-bottom: 10%;
    zoom:75%;
 
  }

  .foh5 {
    font-size: 15px;
    color: #fff;
    margin-left: 25px;
  }

  .foogb {
    width: 2px;
    height: 20px;
    border-radius: 0px;
    background-color: #dae305;
    margin-top: 1px;
    margin-left: 25px;
  }

  .mainlogo {
    width: 70%;
  }

  .mainlogomd {
    width: 70%;
  }

  .boutmd {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 69px;
  }

  .boutp {
    font-size: 18px;
    text-align: center;
    text-decoration: justify;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .Trenheading {
    margin-left: 4px;
    position: absolute;
    font-size: 16px;
    left: 49%;
    margin-top: 44px;
  }

  .Trenmaindiv {
    width: 85%;
    margin: auto;
    margin-top: 20px;
  }

  .Selectmd {
    width: 18%;
    position: absolute;
    margin-top: 12%;
    margin-left: 40%;
    z-index: 10;
  }

  .sec5md {
    width: 100%;
    margin-top: 69px;
    margin-bottom: 69px;
    display: flex;
    flex-direction: column;
  }

  .moodmd {
    width: 100%;
    margin-bottom: 30px;
  }

  .moodheading {
    text-align: center;
    font-size: 25px;
    color: #4b4b4b;
  }

  .moodimgd {
    width: 80%;

    margin: auto;
    margin-top: 2%;
  }

  .appiconmd {
    width: 45%;
    margin: auto;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .appicon {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .booksliderdiv {
    width: 100%;
  }

  .tactmd {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .tactimgdiv {
    width: 100px;
    height: 99px;
    margin-right: auto;
    margin-left: auto;
  }

  .tactpdiv {
    width: 97%;
    margin-top: 25px;
    margin-right: auto;
    margin-left: auto;
  }

  .tactp {
    font-size: 14px;
    text-align: center;
    text-decoration: justify;
    padding:0px 10px;
  }

  .tactbandiv {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
  }

  .decolibdiv {
    width: 100%;

    margin-top: 22px;
  }

  .decolibhone {
    color: #4b4b4b;
    margin-top: 16.8%;
    position: absolute;
    font-size: 20px;
    left: 4.5%;
  }

  .decolibhtwo {
    color: #4b4b4b;
    margin-top: 18% !important;
    position: absolute;
    font-size: 15px;
    left: 4.5%;
    font-weight: 350;
  }

  .decolibimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hdswipslide {
    height: 200px;
  }

  .homediariesh {
    text-align: center;
    font-size: 20px;
    color: #404041;
    margin-bottom: 25px;
  }

  .swiperbookh {
    font-size: 20px;
    color: #000;
    position: absolute;
    margin-left: 36%;
    margin-top: 100px;
  }

  .bookmd {
    margin-bottom: 42px;
  }

  .swiperbookinp {
    width: 77px;
    height: 30px;
    position: absolute;
    margin-left: 42%;
    margin-top: 138px;
    background-color: #fff;
    border: 0px solid;
  }

  .booksliderdiv {
    width: 88%;
    margin-left: 5%;
  }


  :root {
    --swiper-navigation-size: 24px !important;
  }

  

  .Curatedheading {
    text-align: center;
    font-size: 20px;
    color: #404041;
    margin-bottom: 25px;
  }

  .Curatedtitle {
    text-align: center;
    font-size: 10px;
    color: #404041;
  }

  .footermd {
    width: "100%";
    background-color: #414042;
  }

  /* .footersmd {
      width: 100%;
      padding: 15px;
      display: flex;
      padding-top: 50px;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: flex-start;
    }  */

  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: -1px !important;
    z-index: 1;
  }

  .curbc {
    width: 23px;
    height: 40px;
    border-radius: 80px;
    border: 1.5px solid #000;
    position: absolute;
    margin-left: 46.5%;
    margin-top: 191.5%;
  }

  .curbdotc {
    width: 3px;
    height: 3px;
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    top: 5px;
    left: 42.4%;
  }

  .hdmdeone {
    width: 23px;
    height: 40px;
    border-radius: 80px;
    border: 1.5px solid #fff;
    position: absolute;
  }

  .hdmdetwo {
    width: 3px;
    height: 3px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    top: 5px;
    left: 42.4%;
  }
}

/* Media Query Phone------------------end---------- */

@media only screen and (min-width: 1280px) {
 /* Decor Details Css */

 .decorbody {
  width: 100%;
  padding-left: 15% !important;
  padding-right: 5%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.decormainimg {
  width: 35% ;
  height: 100%;
  margin-right: -8% ;
  margin-bottom: 25px;
}

.decormainimg2 {
  margin: 10px auto;
}

.decormainimg3 {
  width: 548px;
  height: 609px;
}

.decorsubimg {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.decortextbody {
  width: 50%;
  height: 100%;
  padding-top: 3%;
}

.decorheadmain {
  font-size: 22px;
  font-weight: bolder;
  color: #404041;
}

.decorsubheadmaindiv {
  display: flex;
  flex-direction: row;
}

.decorsubheadmain {
  font-size: 17px;
  font-weight: bolder;
  color: #404041;
}

.decorsubheadmain2 {
  margin-left: 8px;
  font-size: 17px;
  font-weight: 500;
  color: #404041;
}

.decortext {
  font-size: 22px;
  font-weight: 400;
  color: #404041;
  line-height: 28px;
}

.decorsubheadmain3 {
  font-size: 17px;
  font-weight: bolder;
  color: #404041;
  margin-top: 25px;
  margin-bottom: 25px;
}

.decorbrandlogosbody {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.decorbrandlogos {
  width: 10%;
  margin: auto 5px auto 20px;
}

 .navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0% !important;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.thirdhead {
  height: 100%;
  width: 6% !important;
}




}








/* Media Query Laptop & Desktop------------------start---------- */
@media only screen and (min-width: 1600px) {

  /* Decor Details Css */

  .decorbody {
    width: 100%;
    padding-left: 15% !important;
    padding-right: 5%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .decormainimg {
    width: 35% ;
    height: 100%;
    margin-right: -8% !important;
    margin-bottom: 25px;
  }

  .decormainimg2 {
    margin: 10px auto;
  }

  .decormainimg3 {
    width: 548px;
    height: 609px;
  }

  .decorsubimg {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  .decortextbody {
    width: 50%;
    height: 100%;
    padding-top: 3%;
  }

  .decorheadmain {
    font-size: 22px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmaindiv {
    display: flex;
    flex-direction: row;
  }

  .decorsubheadmain {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmain2 {
    margin-left: 8px;
    font-size: 17px;
    font-weight: 500;
    color: #404041;
  }

  .decortext {
    font-size: 22px;
    font-weight: 400;
    color: #404041;
    line-height: 28px;
  }

  .decorsubheadmain3 {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .decorbrandlogosbody {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .decorbrandlogos {
    width: 10%;
    margin: auto 5px auto 20px;
  }

   .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 0% !important;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .thirdhead {
    height: 100%;
    width: 6% !important;
  }




}


/* Media Query Laptop & Desktop------------------end---------- */

@media only screen and (min-width: 768px) {
  


  
  
  /* Decor Details Css */

  .decorbody {
    width: 100%;
    padding-left: 15% !important;
    padding-right: 5%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .decormainimg {
    width: 50% !important;
    height: 100%;
    margin-right: 3%;
    margin-bottom: 25px;
    z-index: 500;
  }

  .decormainimg2 {
    margin: 10px auto;
  }

  .decormainimg3 {
    width: 548px;
    height: 609px;
  }

  .decorsubimg {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  .decortextbody {
    width: 50%;
    height: 100%;
    padding-top: 3%;
  }

  .decorheadmain {
    font-size: 22px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmaindiv {
    display: flex;
    flex-direction: row;
  }

  .decorsubheadmain {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
  }

  .decorsubheadmain2 {
    margin-left: 8px;
    font-size: 17px;
    font-weight: 500;
    color: #404041;
  }

  .decortext {
    font-size: 22px;
    font-weight: 400;
    color: #404041;
    line-height: 28px;
  }

  .decorsubheadmain3 {
    font-size: 17px;
    font-weight: bolder;
    color: #404041;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .decorbrandlogosbody {
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 1000 !important;
  }

  .decorbrandlogos {
    width: 10%;
    margin: auto 5px auto 20px;
  }

   .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 0% !important;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .thirdhead {
    height: 100%;
    width: 6% !important;
  }
    /* Header Css Starts */
    .me-auto {
      margin-right: auto!important;
      display: flex !important;
    }
    .headerbody{
      height: 23px;
      width: 100%;
      background-color:#dae305 ;
    }
  
    .headernavbar{
    width: 100%;
    height: 135px;
    background-color:#fff;
    padding-left: 4%;
    padding-right: 4%; 
    }
  
  .headerboxshadow {
    box-shadow: -7px 7px 45px 0px rgba(0, 0, 0, 0.1);
  
  }
  
  .navbody{
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 95%;
    height: 135px !important;
    padding-left: 0px !important;
  }

  /*
  .navstack {
    display: none !important;
  }
  */
  
  .navstack{
    padding: 10px;
    display: flex;
    justify-content: right;
    margin-top: 15px;
  }
  
  .navdropdown{
    background-color:#fff;
    border: 0px solid transparent;
    width: 40px;
    height: 28px;
  }
  
  .navdropdownimg{
    width: 29px;
   height: 15px;
  }
  
  .bottomdiv{
    padding: 10px;
    margin-top: auto;
    height: 60px;
    display: flex;
    justify-content: right;
  }
  
    /* Header Css Ends */

  .dditem {
    box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.4);
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    font-family: "AvenirRoman" !important;
    font-weight: bold !important;
    font-size: small !important;
  }

  .ddmenu {
    padding: 0px !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    left: -200px !important;
    transition: .4s !important;
    z-index: 5000;
  }

  .dditem:hover {
    /* box-shadow: 2px 1px 2px 1px rgba(218, 227, 5, 0.4); */
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    font-family: "AvenirRoman" !important;
    font-weight: bold !important;
    font-size: small !important;
    border-bottom: 4px solid rgba(218, 227, 5, 1);
  }

  .SocialiconsF {
    margin-left: 10px !important;
  }

  .SocialiconsI {
    margin-left: 10px !important;
  }

  .SocialiconsF:hover {
    margin-left: 10px !important;
    cursor: pointer;
  }

  .SocialiconsI:hover {
    margin-left: 10px !important;
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 12rem !important;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: rgba(255, 255, 255, 0.3) !important;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .decpimg {
    width: 441px !important;
    height: 488px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.55s ease;
  }

  .decpimg:hover {
    transform: scale(1.04);
  }

 

  .decp2img {
    width: 548px !important;
    height: 226px !important;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all 0.55s ease;
  }

  .decp2img:hover {
    transform: scale(1.04);
  }











  .decp {
    color: #fff;
    font-size: 28px;
    padding-top: 3%;
    text-align: center;
    font-weight: 400;
  }

  .decp:hover {
    color: #bead9d;
    font-size: 28px;
    padding-top: 3%;
    text-align: center;
    font-weight: 400;
  }

  .decimg:hover {
    transform: scale(1.04);
  }

  .hdmdeone {
    width: 23px;
    height: 40px;
    border-radius: 80px;
    border: 1.5px solid #fff;
    position: absolute;
    margin-left: 16.5%;
    margin-top: 2%;
  }

  .hdmdetwo {
    width: 3px;
    height: 3px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    top: 5px;
    left: 42.4%;
  }

  .Trenheading {
    margin-left: 7px;
    position: absolute;
    font-size: 28px;
    left: 49.5%;
    margin-top: 6%;
    font-family: "AvenirRoman";
    font-size: 28px;
  }

  .Trenmaindiv {
    width: 40%;
    margin: auto;
    margin-top: 47px;
  }

  .boutmd {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 103px;
    margin-bottom: 69px;
  }

  .boutp {
    font-size: 20px;
    text-align: center;
    text-decoration: justify;
    line-height: 30px;
    margin-bottom: 20px;
    color: #6d6f71;
  }

  .Selectmd {
    width: 18%;
    position: absolute;
    margin-top: 12%;
    margin-left: 44.2%;
    z-index: 10;
  }

  .Selectmd-text {
    margin-left: -100px; 
    font-weight: 700; 
    font-size: 22px; 
    color: #616161;
  }

  .sec5md {
    width: 100%;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: 69px;
    margin-bottom: 69px;
    display: flex;
    flex-direction: row;
  }

  .moodmd {
    width: 50%;
    height: 525px;
  }

  .moodheading {
    text-align: center;
    font-size: 28px;
    font-family: "AvenirRoman";
    color: #4b4b4b;
  }

  .moodimgd {
    width: 80%;
    height: 380px;
    margin: auto;
    margin-top: 2%;
  }

  .appiconmd {
    width: 31%;
    margin: auto;
    margin-left: 38%;
    margin-top: 0px;
  }

  .appicon {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .booksliderdiv {
    width: 50%;
  }

  .tactmd {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .tactimgdiv {
    width: 150px;
    height: 149px;
    margin-right: auto;
    margin-left: auto;
  }

  .tactbandiv:hover {
    cursor: pointer;
  }

  .tactpdiv {
    width: 28%;
    margin-top: 25px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 25px;
  }

  .tactp {
    font-size: 20px;
    text-align: center;
    text-decoration: justify;
  }

  .tactbandiv {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    border-radius: 15px;
    overflow: hidden;
  }

  .tactbanimg {
    border-radius: 5px;
  }

  .decolibdiv {
    width: 100%;

    height: 600px;
    margin-top: 94px;
  }

  /* .decolibhone {
      color: #4b4b4b;
      
      margin-top: 12.8%;
      position: absolute;
      font-size: 40px;
      left: 8.8%;
     
    transition: .5s;
    } */

  .decolibhone1 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 9%;
    animation-delay: 1s;
  }

  .decolibhone2 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 10.2%;
    animation-delay: 1.2s;
  }

  .decolibhone3 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 11.4%;

    animation-delay: 1.4s;
  }

  .decolibhone4 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 13%;
    animation-delay: 1.6s;
  }

  .decolibhone5 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 14.4%;

    animation-delay: 1.8s;
  }

  .decolibhone6 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 15.4%;

    animation-delay: 2s;
  }

  .decolibhone7 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 16.4%;

    animation-delay: 2.2s;
  }

  .decolibhone8 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 17.6%;

    animation-delay: 2.4s;
  }

  .decolibhone9 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 18.4%;

    animation-delay: 2.6s;
  }

  .decolibhone10 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 20%;

    animation-delay: 2.8s;
  }

  .decolibhone11 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 21%;
    animation-delay: 3s;
  }

  .decolibhone12 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 21.5%;

    animation-delay: 3.2s;
  }

  .decolibhone13 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 22.7%;

    animation-delay: 3.4s;
  }

  .decolibhone14 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 23.4%;

    animation-delay: 3.6s;
  }

  .decolibhone15 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 24.5%;

    animation-delay: 3.8s;
  }

  .decolibhone16 {
    color: #4b4b4b;

    margin-top: 12.8%;
    position: absolute;
    font-size: 40px;
    left: 25.4%;

    animation-delay: 4s;
  }

  /* .decolibdiv h5:hover {
      text-shadow: 2px 2px 4px #000000 ;
      color: #FFF;
      margin-top: 12.8%;
      position: absolute;
      font-size: 40px;
      left: 4.5%;
    }
  
    .decolibdiv p:hover {
      text-shadow: 2px 2px 4px #000000 ;
      color: #FFF;
      margin-top: 15.2%;
      position: absolute;
      font-size: 22px;
      left: 4.5%;
      margin-left: 10px;
      font-weight: 350;
    } */

  .decolibhtwo {
    color: #4b4b4b;
    margin-top: 15.2%;
    position: absolute;
    transition: 0.5s;
    font-size: 22px;
    left: 8.8%;
    margin-left: 10px;
    font-weight: 350;
  }

  .decolibimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hdswipslide {
    height: 857px;
  }

  .homediariesh {
    text-align: center;
    font-size: 28px;
    font-family: "AvenirRoman";
    color: #404041;
    margin-bottom: 25px;
  }

  .swiperbookh {
    font-family: "AvenirRoman";
    color: #404041;
    width: 83%;
    margin-left: 9%;
    height: 50px;
    position: absolute;
    margin-top: 190px;
    text-align: center;
    font-size: 22px;
  }

  .swiperbookinp {
    width: 65px;
    height: 30px;
    position: absolute;
    margin-left: 43.5%;
    margin-top: 240px;
    background-color: #fff;
    border: 0px solid;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
  }

  .swiperbookimg {
    width: 380px !important;
    height: auto;
  }

  .booksliderdiv {
    width: 33%;
    margin-left: 10%;
  }





  :root {
    --swiper-navigation-size: 24px !important;
  }

  

  .Curatedmd {
    width: 100%;
    margin-top: 94px;
  }

  .Curatedheading {
    text-align: center;

    color: #404041;

    font-family: "AvenirRoman";
    font-size: 28px;
  }

  .Curatedtitle {
    text-align: center;
    font-size: 25px;
    color: #404041;
  }

  .footermd {
    width: "100%";
    background-color: #414042;
    height: 93px;
  }

  .footersmd {
    height: 60px;
    width: 1150px;
    padding: 15px;
    display: flex;
    padding-top: 29px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
  }

  .bookmd {
    margin-bottom: 42px;
  }

  .curbc {
    width: 19px;
    height: 34px;
    border-radius: 80px;
    border: 1.5px solid #000;
    position: absolute;
    margin-left: 53.15%;
    margin-top: 27%;
  }

  .curbdotc {
    width: 3px;
    height: 3px;
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    top: 5px;
    left: 42.4%;
  }

  .mainlogo {
    width: 78px;
    height: 96px;
  }

  .mainlogomd {
    width: 78px;
    height: 96px;
  }

  .foh5 {
    color: #fff;
    border-right: 2px solid rgba(218, 227, 5, 1);
    border-left: 0px;
    text-align: center;
    margin-top: 4.5px;
    font-family: "AvenirRoman";
    text-decoration: none !important;
    padding-right: 55px;
    padding-left: 0%;
  }

  .foh5:hover {
    color: #fff;
    margin-left: 25px;
    margin-top: 4.5px;
    font-family: "AvenirRoman";
    text-decoration: none !important;
  }

  .foogb {
    width: 2px;
    height: 20px;
    border-radius: 0px;
    background-color: #dae305;
    margin-top: 5px;
    margin-left: 25px;
  }

  .navtext {
    margin: auto;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    font-family: "AvenirRoman";
    padding-top: 20px;
  }

  /* .navlink::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: -1;
      transition: transform 0.5s;
      transform-origin: 0 0;
      transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    }
    .navlink2::before {
      transform: scaleY(0);
    }
    .navlink2::after {
      transform: scaleY(1);
    } */
  .navlink:hover {
    display: flex;
    padding: 10px;
    background-color: #f8f8f8;
    color: #fff !important;
    transition-timing-function: linear;
    transition-timing-function: cubic-bezier(0, 0, 1, 1);
    border-bottom: 8px solid #dae305;
  }

  .navround {
    width: 18px;
    height: 135px;
    border-radius: 50%;
    background-color: #dae305;
    margin-top: 5px;
    margin-right: 25px;
  }

  .nav-link {
    display: block;
    padding: 0rem 0rem !important;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .navlink {
    display: flex;
    padding: 10px;
    width: 155px;
    position: relative;
    height: 135px;
    border-bottom: 8px solid transparent;
  }

  .brandsdiv {
    width: 100% !important;
    height: 137px;

    /* border: 1px solid blue; */
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    /* box-shadow: 0px -32px 39px -38px rgb(0, 0, 0, 12%),
        0px 13px 20px -18px rgb(0, 0, 0, 25%); */

    /* -32px 39px -38px rgb(0, 0, 0, 12%),0px 13px 20px -13px rgb(0,0,0, 25%);  */
  }

 /*Curated*/

  /* Our Design Studio Css  */

  .Submainhead {
    font-size: 22px;
    font-weight: bold;
    color: #4b4b4b;
    text-align: center;
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .Imgheight {
    min-height: 607px;
  }

  .imgsize {
    width: 100%;
    height: 432px;
  }

  .upfooter {
    width: 100%;
    padding-left: 28.5%;
    height: 267%;
    display: flex;
    flex-direction: row;
  }

  .upfootertext {
    width: 30%;
    height: 100%;
    padding: 25px;
  }

  .upfootertext1 {
    font-size: 22px;
    font-weight: 900;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
  }

  .upfootertext2 {
    font-size: 17px;
    font-weight: 500;
    font-family: "CenturyGothic";
    color: #404041;
    text-align: center;
  }

  .upfooternum {
    font-size: 17px;
    font-weight: 500;
    font-family: "AvenirRoman";
    color: #404041;
    text-align: center;
    margin-top: 10px;
  }

  .imgupfooter {
    margin-left: 49%;
    width: 20px;
    height: 25px;
  }

  .imgupfooter1 {
    margin-top: 3%;
    width: 20px;
    height: 172px;
  }

  /* Our Design Studio Css  */

  /* Tech Spec Css Starts */

  .subheadmain {
    font-size: 22px;
    font-weight: bold;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .imgtop {
    margin-left: 22%;
    margin-right: 22%;
    width: 56%;
  }

  .imgbottom {
    margin-left: 31.5%;
    margin-right: 30%;
    width: 38.5%;
  }

  .headbottom {
    font-size: 22px;
    font-weight: bold;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
    margin-top: 50px;
  }

  /* Tech Spec Css Ends */

  /* Contact us Css Starts */

  .contacttext {
    font-size: 17px;
    font-weight: normal;
    font-family: "AvenirRoman";
    color: #4b4b4b;
    text-align: center;
  }

  .contactbody {
    min-height: 656px;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding-top: 1.8%;
  }

  .contactbody2 {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
  }
  /* Contact us Css Ends */

  /* About us Css Starts */

  .aboutbody {
    height: 735px;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding-left: 23% !important;
    padding-right: 18%;
    padding-top: 1.8%;
  }

  .aboutimg {
    margin-top: 25%;
    width: 248px;
    height: 425px;
    margin-left: 0px !important;
  }
  .contactheadmain {
    font-size: 22px;
    font-weight: bold;
    font-family: "AvenirRoman";
    color: #4b4b4b;
  }

  .abouttxt {
    font-size: 17px;
    font-weight: normal;
    font-family: "CenturyGothic";
    color: #404041;
    line-height: 20px;
  }
  .abouttxt1 {
    font-size: 17px;
    font-weight: normal;
    font-family: "CenturyGothic";
    color: #404041;
  }
  .abouttxtbold {
    font-size: 17px;
    font-weight: bold;
    font-family: "CenturyGothic";
    color: #404041;
  }

  .abouttxtshadow {
    padding-left: 24px;
    font-size: 17px;
    font-weight: lighter;
    font-family: "CenturyGothic";
    color: #404041;
    box-shadow: -7px 7px 15px 0px rgba(0, 0, 0, 0.1);
  }

  .abouttxtshadowbold {
    padding-left: 24px;
    font-size: 17px;
    font-weight: bold;
    font-family: "CenturyGothic";
    color: #404041;
    box-shadow: -7px 7px 15px 0px rgba(0, 0, 0, 0.1);
  }

  /* About us Css Ends */

  /* DesignDiaries Css Starts */

  .mainheaddd {
    font-size: 22px;
    font-weight: bold;
    color: #4b4b4b;
    text-align: center;
    margin-top: 49px;
    margin-bottom: 25px;
  }

  /* .sec1dd {
    height: 484.4px;
    margin-left: 153.43px;
    margin-right: 153.43px;
    display: flex;
    flex-direction: row;
    margin-bottom: 51px;
  } */

  .img1div {
    width: 43.3%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 31px;
  }

  .img2div {
    width: 23.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 31px;
  }

  .img3div {
    width: 28%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s2img2div {
    width: 28%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 31px;
  }

  .s2img3div {
    width: 43.3%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s3img2div {
    width: 53.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .s4img1div {
    width: 38.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 31px;
  }

  .s4img2div {
    width: 19.7%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 31px;
  }

  .s4img3div {
    width: 36.6%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 31px;
  }

  .s5img1div {
    width: 53.2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 31px;
  }

  .s6img3div {
    width: 38.8%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* DesignDiaries Css Ends */

  /* decorbook css  */
  .nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;
  }

  .nav {
    display: flex !important;
    flex-wrap: wrap !important;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
    list-style: none !important;
    justify-content: center !important;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #4b4b4b !important;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-link {
    display: block;
    margin-right: 25px !important;
    margin-left: 25px !important;
    color: #a7a9ac !important;
    text-decoration: none;
    font-size: 17px !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  /* Footer Css Starts */

  .topdiv {
    height: 23px;
    width: 100%;
    background-color: #dae305;
    margin-top: 50px;
  }

  .footercontact {
    border-right: 1px solid transparent;
  }

  /* Footer Css End */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 5 0px;
}

.swiper-slide {
  background-position: center;
  background-size: contain;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

#searchbar::placeholder {
  color: #000;

  font-size: 28px;
  opacity: 1;
}

label {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  margin-left: 4px;
}

.form-check-input {
  width: 18px !important;
  height: 18px !important;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid rgba(0, 0, 0, 1) !important;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input-sub {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #e2e2e2;
  background-repeat: no-repeat;
  background-position: center;
}


.zoom {
  position: relative !important;
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: crosshair;
}
.zoom img:hover {
  opacity: 0;
}
.zoom img {
  transition: opacity .5s;
  display: block;
  width: 100%;
}

.magnifier {
  width: 640px;
  margin: 3.5% auto;
  position: relative;
  overflow: hidden !important;
}

.magnifier:hover {
  cursor: crosshair;
}

.magnifier-2 {
  width: 640px;
  margin: 12% auto;
  position: relative;
  overflow: hidden !important;
  cursor: crosshair;
}

.magnifier-3 {
  width: 80% !important;
  margin: 5% auto;
  position: relative;
  overflow: hidden !important;
  cursor: crosshair;
}

.magnifier-3 img {
  width: 80% !important;
}

.magnifier-4 {
  width: 1000px !important;
  margin: 12.4% auto;
  position: relative;
}

.magnifier-4 img {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 768px){


  .magnifier-3 {
    width: auto !important;
    margin: auto;
    margin-top: 45%;
    margin-bottom: 45%;
    position: relative;
    padding: 20px;
  }

  .magnifier-4 {
    width: auto !important;
    margin: auto;
    margin-top: 65%;
    position: relative;
    padding: 20px;
  }


  .magnifier-2 {
    width: auto !important;
    margin: 30% auto;
    position: relative;
    padding: 50px;
  }

  .magnifier {
    width: auto !important;
    margin: 30% auto;
    position: relative;
    padding: 50px;
  }


}

.magnifier-2:hover {
  cursor: none;
/*   cursor: crosshair; */
}

.large {
  width: 200%;
  height: 200%;
  position: absolute;
  /*background-repeat: no-repeat !important;*/
  display: none;
}

.small {
  display: block;
}


.centered-div
{
  position: fixed;
  top:0;
  left: 0;
  height: 100%;
  width: 100% !important;
  margin: 0px !important;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2000;
  overflow-y: auto;
}

.centered-div2
{
  position: fixed;
  top:0;
  left: 0;
  height: 100%;
  width: 100% !important;
  margin: 0px !important;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2000;
  overflow-y: auto;
}

.close {
    content: "X";
    position: relative;
    top: 0;
    right: 0;
    margin: 33px 50px 0 0;
    color: black !important;
    font-family: cursive;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
    z-index: 2000;
}


.dbl-main-sec, .dbl-sub-sec {
  overflow: hidden;
}

.dbl-sub-sec img {
  transition: transform .2s;
}

.dbl-sub-sec img:hover {
  transform: scale(1.1);
}

.lb-btn, .dbl-main-sec {
  cursor: pointer;
}

/*Progress Bar*/

#restaurantmap {
  height: auto;
  width: 100%;
}

.form-control:focus, .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.colorCollection img {

  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  border-radius: 25px;
  
}

.cc-popup {
  position: relative;
  z-index: 2000 !important;
}

.colorCollectionTop img {
  box-shadow: none;
  border-radius: 5px !important;
  border: 0.5px solid grey;
}


.odc {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

@media (max-width: 768px) {
  .odc {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

.odc .input-group {
  border-radius: 5px;
}

.odc #restaurantmap {
  border-radius: 10px;
}

.inner-shadow {
  box-shadow: inset 0 0 15px 0 #c7c7c7;
}

.inner-shadow-search {
  box-shadow: inset 0 0 5px 0 #c7c7c7;
}

.inner-shadow-search i {
  height: 27px;
}

#button-addon3 {
  padding: 0px 5px 0px 0px !important;
}

.ods-input, .ods-btn {
  height: 27px !important;
}

.footermd {
  width: "100%";
  background-color: #414042;
  height: auto !important;
}

.footer-links-container {
  padding: 40px 20px 40px 20px;
}

.footer-links:first-child {
  border-left: none;
}

.footer-links {
  color: #fff;
  transition-duration: 0.2s;
  padding-right: 20px;
  padding-left: 20px;
  border-left: 1px solid #dae305;
  display: inline;
  font-family: calibri;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.footer-links:hover {
  text-decoration: none;
  color: #dae305;
}

@media (max-width: 512px) {
  .footer-links {
      color: #fff;
      font-size: 14px;
      transition-duration: 0.2s;
      padding-right: 5px;
      padding-left: 5px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-left: none;
      border-bottom: 0.1px solid #9c9e66;
      display: block;
      text-align: left;
  }

  .footer-links-container {
    padding: 20px 20px 20px 20px;
  }

}

.footer-links:last-child {
  border-bottom: none;
}

/*

.navlink {
  width: auto !important;
}
*/

.dlist-item {
  margin: 6rem 3rem 6rem 3rem;
}

@media (max-width: 768px) {

  /*

  .navstack {
    display: block !important;
  }

  .bottomdiv {
    margin-top: 0px !important;
    justify-content: center !important;
    margin-right: -15px;
  }
*/

}

/*
.bottomdiv {
  margin-top: 50px;
}
*/

.dlist-container {
  --bs-gutter-x: 0 !important;
}

.decp {
  color: #fff;
  font-size: 23px;
  padding-top: 5%;
  text-align: center;
  font-weight: 400;
}

@media (max-width: 768px) {
  /*
  .bottomdiv {
    margin-top: 0px !important;
    justify-content: center !important;
  } 
  */
  .dlist-item {
    margin: 1.5rem 3rem 1.5rem 3rem !important;
  }
}

.popup-top {
  width:100%; 
  height:100px; 
  background:#fff; 
  color:black; 
  margin-bottom:25px;
}

.image-title-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding-top: 37px;
  text-align: center;
}

@media (max-width: 768px) {
  .popup-top {
    width:100%; 
    height:100px; 
    background:#fff; 
    color:black; 
    margin-bottom:25px;
  }
  .close {
    content: "X";
    position: relative;
    top: 0;
    right: 0;
    margin: 33px 20px 0 0;
    color: black !important;
    font-family: cursive;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
  }
  .image-title-popup {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    padding-top: 17px;
    text-align: center;
    display: inline-block;
    width: 100%;
  }
}

.img-popop-dl .magnifier-2 {
  margin-top: 10% !important;
  margin-bottom: 10% !important;
  cursor: zoom-in;
}

.img-popop-dl .close {
  color: #fff !important;
}

.sec1dd img {
  cursor: pointer;
}

.img-popop-dl .magnifier-2 img {
  transition: width 1s;
}

.zoom-class {
  width: 100% !important;
  height: 100% !important;
  transition: width 1s;
}

.zoom-grab {
  cursor: grab !important;
  cursor: -o-grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
}

.dds-control .left, .dds-control2 .left2 {
  position: fixed;
  top: 50%;
  left: 0;
  margin-left: 40px;
  color: #fff;
  font-size: 20px;
  z-index: 2000;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  opacity: 0.4;
}

.dds-control .right, .dds-control2 .right2 {
  position: fixed;
  top: 50%;
  right: 0;
  margin-right: 65px;
  color: #fff;
  font-size: 20px;
  z-index: 2000;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  opacity: 0.4;
}

.dds-control .right:hover, .dds-control .left:hover, .dds-control2 .right2:hover, .dds-control2 .left2:hover {
  opacity: 1;
}

.trending-row {
  padding-left: 12%;
  padding-right: 1%;
}

.trending-sub-row {
  width: 65%;
}

@media (max-width: 767px) {
  .trending-row {
    padding-left: 10%;
    padding-right: 10%;
  }
  .trending-sub-row {
    width: 100%;
  }


  .dds-control .left {
    position: fixed;
    top: 50%;
    left: 0;
    margin-left: 35px;
    color: #000;
    font-size: 20px;
    z-index: 2000;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 50%;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    opacity: 0.4;
  }
  
  .dds-control .right {
    position: fixed;
    top: 50%;
    right: 0;
    margin-right: 35px;
    color: #000;
    font-size: 20px;
    z-index: 2000;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 50%;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    opacity: 0.4;
  }

  .dds-control .right:hover, .dds-control .left:hover {
    opacity: 1;
  }


}


 /* Curated Slider CSS STARTS HERE  */
 
 #maindiv {
  width: 100%;
  height: 550px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -webkit-perspective: 1200px;
  perspective: 1200px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

#spin-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;

  display: flex;
  margin: auto;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /* -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg); */
}

#drag-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /* -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg); */
  /*animation: rotation 20s infinite linear;*/
}

/*
#drag-container:hover{
  animation-play-state: paused;
}
*/

#drag-container img,
#drag-container video {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 120px;
  font-size: 50px;
  text-align: center;
  /* -webkit-box-shadow: 0 0 8px #fff;
            box-shadow: 0 0 8px #fff; */
  -webkit-box-reflect: below 10px
    linear-gradient(transparent, transparent, #0005);
}

#drag-container img:hover,
#drag-container video:hover {
  /* -webkit-box-shadow: 0 0 15px #fffd;
            box-shadow: 0 0 15px #fffd; */
  -webkit-box-reflect: below 10px
    linear-gradient(transparent, transparent, #0007);
}

#drag-container p {
  font-family: Serif;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateX(90deg);
  transform: translate(-50%, -50%) rotateX(90deg);
  color: #fff;
}

#ground {
  width: 900px;
  height: 900px;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateX(90deg);
  transform: translate(-50%, -50%) rotateX(90deg);
  background: -webkit-radial-gradient(
    center center,
    farthest-side,
    #9993,
    transparent
  );
}

#music-container {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

:root {
  --right: rotateY(360deg);
  --left: rotateY(0deg);
}

@keyframes rotation{
  from{
    transform: var(--left);
  }
  to{
    transform: var(--right);
  }
}

/* Curated Slider CSS eNDS HERE  */


@media (max-width: 767px){
  #maindiv {
    zoom: 40% !important;
  }
  .Curatedheading {
    margin-top: 50px;
  }
}

@media (max-width: 400px){
  #maindiv {
    zoom: 30% !important;
  }
}

.dbook-links {
  text-decoration: none;
  color: grey;
  margin: 10px;
}

.dbook-links a {
  text-decoration: none;
  color: black;
  margin: 10px;
}

.dbl-sub-sec {
  overflow: hidden !important;
}

.dbook-img-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-bottom: -10px;
  width: 100%;
  background: #f5f5f5;
  color: #000;
  padding: 10px;
  margin-left: 10px;
}

.dbook-img-text:hover {
  display: block;
}

.decimg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.55s ease;
  width: 75%;
}

.decormainimg {
  width: 40% !important;
}

.decortextbody {
  width: 50% !important;
  margin-left: 10% !important;
}

.mbdiv {
  width: 100%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}


.Trenimg {
  margin: 25px;
}

.brandsdiv {
  width: 100% !important;
  height: auto;
}

.main-image {
  width: 550px;
  height: 600px;
  transition: all 0.55s ease;
}

.decorsubimg1 {
  width: 266px;
  height: 240px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  border-radius: 25px;
  margin-right: 20px;
}

.decorbrandlogosbody img {
  width: 133px;
  height: 120px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.decorsubimg1, .decorbrandlogosbody img {
  transition: all 0.55s ease;
}

.decorsubimg1:hover {
  transform: scale(1.03);
}

.Selectmd {
  width: 18%;
  position: absolute;
  margin-top: 9%;
  margin-left: 44.2%;
  z-index: 10;
}

.Selectmd-text {
  margin-left: -100px; 
  font-weight: 700; 
  font-size: 22px !important; 
  color: #616161;
}

.brandsdiv-1 {
  overflow: hidden !important;
  width: 60%;
  padding-top: 19px;
  padding-bottom: 20px;
  height: auto;
}

.brandsdiv-2 {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  width: 120%;
  margin-left: -9.5%;
}

@media (max-width: 767px){

  .Selectmd {
    width: 50% !important;
    position: absolute;
    margin-top: 30%;
    margin-left: 30%;
    z-index: 10;
  }
  
  .Selectmd-text {
    margin-left: -50px; 
    font-weight: 700; 
    font-size: 22px !important; 
    color: #616161;
  }

  .main-image {
    width: 367px;
    height: 400px;
  }
  .decorsubimg1 {
    width: 137.5px;
    height: 127.5px;
    margin-right: 15px;
  }
  .decorbrandlogosbody img {
    width: 175px;
    height: 155px;
    margin-right: 10px;
  }
}


@media (max-width: 512px){

  .select-sec {
    overflow: hidden !important;
  }
  
  .select-sec img {
    /* zoom: 250% !important; */
    transform: scale(2.5);
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .Selectmd {
    width: 70% !important;
    position: absolute;
    margin-top: 35%;
    margin-left: 15%;
    z-index: 10;
  }
  
  .Selectmd-text {
    margin-left: 0; 
    font-weight: 700; 
    font-size: 18px !important; 
    color: #424242;
  }

  .main-image {
    width: 244px;
    height: 266px;
  }
  .decorsubimg1 {
    width: 116px;
    height: 104px;
    margin-right: 10px;
  }
  .decorbrandlogosbody img {
    width: 58px;
    height: 52px;
    margin-right: 5px;
  }


  .brandsdiv-1 {
    overflow: hidden !important;
    width: 100%;
    padding-top: 19px;
    padding-bottom: 20px;
    height: auto;
  }
  
  .brandsdiv-2 {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    width: 100%;
    margin-left: 0%;
  }
  
  .Trenimg {
    margin: 15px 14px 15px 14px;
    width: 12%;
  }

}

.trending-sub-row img{
  cursor: pointer;
}

/* .texthov {
  width: 100%;
  height: 600px;
  position: absolute;
} */

@keyframes shake {
  0% {
    transform: translateY(0rem);
  }

  50% {
    transform: translateY(-1rem);
  }

  100% {
    transform: translateY(-2rem);
  }
}

.texthov:hover span:nth-child(1) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 2.5s infinite;
}

.texthov:hover span:nth-child(1) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.5s;
  animation-delay: 0s;
}

.texthov:hover span:nth-child(2) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.535s;
  animation-delay: 0.035s;
}

.texthov:hover span:nth-child(3) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.57s;
  animation-delay: 0.07s;
}

.texthov:hover span:nth-child(4) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.605s;
  animation-delay: 0.105s;
}

.texthov:hover span:nth-child(5) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.64s;
  animation-delay: 0.14s;
}

.texthov:hover span:nth-child(6) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.675s;
  animation-delay: 0.175s;
}

.texthov:hover span:nth-child(7) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.71s;
  animation-delay: 0.21s;
}

.texthov:hover span:nth-child(8) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.745s;
  animation-delay: 0.245s;
}

.texthov:hover span:nth-child(9) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.78s;
  animation-delay: 0.28s;
}

.texthov:hover span:nth-child(10) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.815s;
  animation-delay: 0.315s;
}

.texthov:hover span:nth-child(11) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.835s;
  animation-delay: 0.35s;
}

.texthov:hover span:nth-child(12) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.885s;
  animation-delay: 0.385s;
}

.texthov:hover span:nth-child(13) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.92s;
  animation-delay: 0.42s;
}

.texthov:hover span:nth-child(14) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.955s;
  animation-delay: 0.455s;
}

.texthov:hover span:nth-child(15) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 1.99s;
  animation-delay: 0.49s;
}

.texthov:hover span:nth-child(16) {
  margin-top: 12.8%;
  position: absolute;
  font-size: 40px;
  animation: waviy 2.25s;
  animation-delay: 0.525s;
}

/* .texthov:hover p {
   
    margin-top: 15.2%;
    position: absolute;
    font-size: 22px;
    left: 8.8%;
    margin-left: 10px;
    font-weight: 350;
  } */

@keyframes waviy {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
}

.texthov span {
  display: none !important;
}

#tdl {
  display: inline-block;
  font-family: var(--bs-body-font-family);
  font-size: 2.6rem;
  --duration: 1s;
  --stager: 10ms;
  position: absolute;
  top: 35%;
  left: 8.9%;
  color: #4b4b4b;
  font-weight: 400;
}

#tdl p {
  display: inline-block; 
  animation-fill-mode: forwards;
}

#tdl.flip-in p {
  animation: flipin var(--duration) ease-in calc(var(--i) * var(--stager)) 1;
}

#tdl.flip-out p {
  animation: flipout var(--duration) ease-in calc(var(--j) * var(--stager)) 1;
}


@keyframes flipout {
  100% {
    transform: rotateX(360deg);
  }  
}

@keyframes flipin {
  100% {
    transform: rotateX(360deg);
  }  
}


@media (max-width: 512px) {
  /* #tdl {
    display: inline-block;
    font-family: var(--bs-body-font-family);
    font-size: 1.2rem;
    --duration: 1s;
    --stager: 10ms;
    position: absolute;
    top: 9%;
    left: 4.2%;
    color: #4b4b4b;
    font-weight: 400;
  } */
  #tdl {
    display: inline-block;
    font-family: var(--bs-body-font-family);
    /* font-size: 1.2rem; */
    --duration: 1s;
    --stager: 10ms;
    position: absolute;
    /* top: 9%; */
    /* left: 4.2%; */
    color: #4b4b4b;
    font-weight: 400;
}
}

.decorList {
  width: 100% !important;
}

.decorList li{
  display:inline-block;
  list-style-type: none !important;
  margin: 15px 30px 15px 30px;
}


.imgmodal {
  position: fixed;
  z-index: 1000;
  padding-top: 0px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* Full height */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  overflow: auto;
  background-color: rgb(0, 0, 0);
  /* Black w/ opacity */
  transition: 0.3s;
}


/* Modal Content (image) */

.content {
  margin: auto;
  display: block;
  height: 90% !important;
  width: 40%;
}

@media (max-width: 767px){
  .content {
    margin: auto;
    display: block;
    height: 70% !important;
    width: 90%;
  }
}

/*

.decimg .product-img {
  width: 85% !important;
  height: 600px !important;
  border-radius: 5%;
  transition: all 0.55s ease;
}

.decorsubimg {
  padding-left: 45px;
  padding-right: 25px;
}

@media (max-width: 767px) {
  .decimg .product-img {
    width: 100% !important;
    height: 450px !important;
  }
  .decorsubimg {
    padding-left: 0px;
    padding-right: 0px;
  }
}

*/

.nav-mobile-show {
  display: none !important;
  /* margin-top: 40px;
  margin-right: -40px; */
}

@media (max-width: 767px) {
  .nav-mobile-hide {
    display: none !important;
  }
  .nav-mobile-show {
    display: block !important;
  }

  .ddmenu {
    height: 100% !important;
    width: 250px !important;
    position: fixed !important;
    margin-left:0px !important;
    top: 0 !important;
    background-color: #fff !important;
    border-right: 1px solid #ccc !important;
    overflow-y: auto !important;
    z-index: 3040 !important;
  }


}

.sidebar {
	height: 100%;
	width: 250px;
	position: fixed;
	margin-left:0px;
	top: 0;
	background-color: #fff;
	border-right: 1px solid #ccc;
	overflow-y: auto;
	transition: .4s;
	z-index: 1040;
}

.sidebar .logo {
	font-size: 25px;
	font-weight: 500;
	height: auto;
	width: 100%;
	text-align: center;
	/*border-bottom: 1px solid #ccc;*/
	margin-bottom: 50px;
	margin-top: 40px;
	padding-top: 6px;
  	z-index: 1000;
  	background-color: #fff;
}


.sidebar .menu {
	padding: 8px 0px 8px 0px;
	font-size: 14px;
	display: block;
}


.sidebar .primary-menu {
		padding-bottom: 15px;
		margin-bottom: 10px;
		background-color: #fff;
		border-bottom: 1px solid #ccc;
}


.menu-heading {
	font-size: 12px;
	color: #ccc;
	text-transform: uppercase;
	margin-left: 15px;
}

.sidebar .primary-menu li {
	font-size: 14px;
	padding: 3px;
	transition: .4s;
	border-color: #ccc;
}

.sidebar .primary-menu li a {
	text-decoration: none;
	color: #1a1a1a;
}

.sidebar .primary-menu li a:hover {
	text-decoration: none;
	color: #7a7a7a;
	cursor: pointer;
}

.sidebar .primary-menu li i {
	color: #ccc;
}

/* .sidebar-icon {
	margin-top: -4px;
} */


@media screen and (max-width: 576px) {

	.sidebar-show {
		margin-left: 0px !important;
	}

	.header {
		width: auto;
		height: 55px;
		border-bottom: 1px solid #ccc;
		padding-left: 0px;
	}

	.header .menu-button {
		display: block;
		position: absolute;
		left: 10px;
		top: 0px;
		height: 20px;
		width: 20px;
		font-size: 20px;
	}

	.overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.2);
		transition: .4s;
		z-index: 1030;

	}

	.logo {
		height: auto;
		margin-top: 80px !important;
		margin-bottom: 50px !important;
	}

	.logo-desktop {
		margin-left: 20px;
	}

	.sidebar {
		height: 100%;
		width: 250px;
		margin-left: -250px;

	}

	.sidebar .menu {
		padding: 0px 0px 10px 0px;
		font-size: 14px;
		margin-bottom: 15px !important;
		display: block;
	}


}

.container-fluid-gdc{
  width: 74%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;

}

.mb-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.select-center { 
  width: 100%;
  height: 196px;
  position: relative;
  border-radius: 0px;
  background-repeat: no-repeat !important;
  background-size: 101% !important;
  background-position: center center !important;
  
}

.scintilla-center { 
  height: 286px;
  width: 100%;
  border-radius: 0px;
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  margin: 5px;
}

.geodesic-center { 
  height: 100%;
  width: 100%;
  border-radius: 0px;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  margin: 0px;
}

.geodesic-center video {
  margin: 0;
  position: absolute;
  top: 0%;
  left:38.7%;
  width: 22%;
  transition: all 0.55s ease;
  border-radius: 0px;
}

.geodesic-center video:hover {
  
}

.select-center img {
  margin: 0;
  position: absolute;
  top: 23%;
  left: 31%;
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  transition: all 0.55s ease;
}

.select-center img:hover {
  transform: scale(1.03);
}

.select-center p {
  margin: 0;
  position: absolute;
  top: 65%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Century Gothic;
  font-size: 13px;
  font-weight: 500;
}

.sci-col {
  padding: 0px;
}

@media (max-width: 767px) {

  .sci-col {
    padding: 5px;
  }
  
  .geo-col {
    padding: 5px;
  }

  .geodesic-center { 
    height: 300px;
    width: 100%;
    border-radius: 0px;
    position: relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    margin: 5px;
  }

  .geodesic-center video {
    top: 0%;
    left: 35.5%;
    width: 29%;
    border-radius: 0px;
  }

  .select-center { 
    height: 157px;
    background-repeat: no-repeat !important;
    background-size: 110% !important;
    background-position: center center !important;
    border-radius: 0% !important;
    margin-top: -10px;
  }

  .select-center img {
    top: 15%;
    left: 25%;
    
    width: auto;
  }

  .select-center p {
    top: 65%;
    font-size: 14px;
    width: 60%;
  }

}



.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 30px !important;
  width: 30px !important;
  outline: black;
  background-size: 100%, 100%;
  /*border-radius: 50%;
  border: 1px solid black;*/
  background-image: none;
}

.carousel-control-next-icon {
  margin-left: -40px !important;
  margin-top: 0px !important;
  /*background: rgba(26, 70, 104, 0.80);*/
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 13px !important;
  font-weight: 700;
  color: black;
}

.carousel-control-prev-icon {
  margin-right: -30px !important;
  margin-top: 0px !important;
  /*background: rgba(26, 70, 104, 0.80);*/
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 13px !important;
  font-weight: 700;
  color: black;
}

/*
.carousel .col-sm-12{
display: inline-block;
margin-left:-4px;
}
*/

.carousel .col-sm-12{
  align-items: center !important;
  text-align: center !important;
  }

.carousel .col-sm-12 img{
width:auto;
height: 400px !important;
}
.carousel .carousel-indicators li{
background-color:red;
}

.carousel .no-padding{
padding-left: 0;
padding-right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
height: 40px;
width: 40px;
outline: rgb(119, 119, 119);
background-size: 100%, 100%;
border-radius: 10%;
border: 1px solid #dae305;
background-color: rgb(202, 202, 202);
background-image: none;
padding: 20px !important;
}

.carousel-control-next-icon {
margin-left: -50px;
margin-top: 10px;
/*background: rgba(26, 70, 104, 0.80);*/
}

.carousel-control-next-icon:after
{
content: '';
font-size: 22px;
font-weight: 700;
color: black;
}

.carousel-control-prev-icon {
margin-right: -40px;
margin-top: 10px;
/*background: rgba(26, 70, 104, 0.80);*/
}

.carousel-control-prev-icon:after {
content: '';
font-size: 22px;
font-weight: 700;
color: black;
}

.desktop-view {
  display: block !important;
}

.mobile-view {
  display: none !important;
}

@media (max-width: 767px) {
  .desktop-view {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
}


.flex-column {
  max-width: 550px;
}

/* img {
  margin: 5px;
} */

.scale {
  transform: scaleY(1.05);
  padding-top: 5px; 
}

.hide, .map-coordinates {
  display: none !important;
}

/* .imgmodal .content {
  display: none !important;
} */

.show{
  display: block !important;
}

/*

.img-container img {
  margin-bottom: 550px;
}

*/

.sub-filters {
  background-color: #eeeeee; 
  padding: 5px; 
  width: fit-content !important;
  text-align: center;
}

.catalog .fa-angle-left {
  position: absolute;
  top: 0%;
  padding-top: 15%;
  left: 5%;
  font-size: 30px;
  cursor: pointer;
  z-index: 1000;
  color: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100px;
  transition: all 0.55s ease;
}

.catalog .fa-angle-right {
  position: absolute;
  top: 0%;
  padding-top: 15%;
  right: 5%;
  font-size: 30px;
  cursor: pointer;
  z-index: 1000;
  color: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100px;
  transition: all 0.55s ease;
}

.catalog .fa-angle-left:hover {
  color: rgba(0, 0, 0, 1);
}

.catalog .fa-angle-right:hover {
  color: rgba(0, 0, 0, 1);
}

.decorbrandlogosbody {
  z-index: 1000 !important;
}

.decorsubimg1 {
  width: 23.4%; 
  background-size: 270px 400px; 
  background-repeat: no-repeat; 
  background-position: center center;
}

.decorsubimg2 {
  width: 48% !important; 
  background-size: cover !important; 
  background-repeat: no-repeat; 
  background-position: center center;
}




.relative {
  /* remove "position: relative;" to see the difference */
  position: relative;
  width: 50%;
  height: 200px;
  background: grey;
}

/* .pointer {
	cursor: pointer;
} */

::ng-deep .pointer{
  cursor: pointer !important; 
  z-index: 500;
}

.pointer-circle {
  height:100%; width:100%; 
  background-color:#fff; 
  border-radius:50%;
}

.slide-container {
	width: 50%;
	margin-top: 7%;
}
	
.relative {
  /* remove "position: relative;" to see the difference */
  position: relative;
  width: 100%;
  height: auto;
  background: grey;
}

.absolute {
	position: absolute;
	left: 0;
	top: 0;
}

#pointer {
	cursor: pointer;
}

.popover {
  min-width: 100px;
  padding-top: 25px;
  margin-left: -40px;
  background-color: transparent !important;
  border-color: transparent !important;
}

.popover-inner {
  color: red;
  padding: 10px;
  background-color: #fff;
  border-radius:  5px !important;
}

.popover .arrow {
	display: none;
}

.popover-title {
	color: orange;
}

.popover-content {
	font-weight: 500;
	font-size: 15px;
	margin-top: 10px;
  color: #000;
}

.popover-heading {
	color: red;
	float: left;
}

.arrow {
	color: black;
	font-size: 15px;
}

.dd-cards {
  width: 33.33333333% !important;
  height: auto;
  display: inline-block;
}

@media (max-width: 767px) {
  .dd-cards {
    width: 100% !important;
    height: auto;
    display: block;
  }
	.popover {
		width: 30%;
	}
	.slide-container {
		width: 90%;
		padding: 20px 20px 20px 20px;
    margin-top: 20%
	}

  
}

.decor-tag {
  position: absolute; 
  margin-top: -60px; 
  margin-left: 0.8%; 
  width: 97%; 
  height: 55px; 
  background-color: rgba(255,255,255, 0.8); 
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 
  z-index: 25;
}

.dbl-sub-sec .dbl-sub-sec .decor-tag {
  position: absolute; 
  margin-top: -57px; 
  margin-left: 0.8%; 
  width: 95%; 
  height: 55px; 
  background-color: rgba(255,255,255, 0.8); 
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 
  z-index: 25;
}

.dt-text {
  margin: 10px 15px 10px 15px;
  color: grey;
  font-size: 25px;
  font-weight: 500;
}

.dt-icon {
  margin: 18px 15px 10px 15px;
  height: 25px;
  width: 25px;
}

.scintilla-cover {
  color: #fff;
  /*background-image: url('https://digitalprojects.davidson.edu/earthday2020/wp-content/uploads/2020/04/night-sky-2030855_1920-1536x410.jpg');*/
  /* background-repeat: no-repeat;
  background-size: cover; */
  height: 517px;
  font-size: 100px;
  font-weight: 400;
  padding-top: 160px;
  position: relative;
  overflow: hidden;
  background-color: #000;;
}

.scintilla-cover video {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  /*  object-fit is not supported on IE  */
  /* object-fit: cover; */
  /* opacity:0.5; */
}
.scintilla-cover .container {
  z-index: 2;
  position: relative;
}


.hidden {
  display: none !important;
}

.decore-tile-container {
	height: 350px; 
  width: 300px; 
  background-color: grey; 
  border-radius: 30px; 
  position: relative; 
  box-shadow: rgb(0 0 0 / 40%) 2px 5px 8px 4px; 
  overflow: hidden;
}

.decore-tile-label {
	position: absolute; 
  bottom: 0; 
  height: 20%; 
  width: 100%; 
  background: rgba(0, 0, 0, 0.5); 
  color: #fff; 
  border-radius: 0px 0px 30px 30px; 
  text-align: center; 
  margin: auto; 
}

.decore-tile-image {
	height: 100%; 
  width: 100%;
  transition: 0.5s;
}

.decore-tile-image:hover {
	transform: scale(1.1);
}

.decore-tile-label-text {
	font-size: 20px;
  margin-top: 0px;
  padding-top: 20px;
  cursor: pointer;
  transition: 0.5s;
  height: 100%;
  width: 100%;
}

.decore-tile-label-text:hover {
  font-size: 22px;
  color: #fff;
}

.decore-tile-label-text a {
	text-decoration: none;
  color: #fff;
}

.decore-tile-label-text a:hover {
  color: #fff;
}

.dbl-sub-sec {
  height: 257px !important;
}

.dbl-main-sec {
  height: 527px !important;
}

.scintilla-widget {
  transition: 0.5s;
}

.scintilla-widget:hover {
  scale: 1.1;
}

.carousel .control-prev.control-arrow:before {
  display: none !important;
}

.decorsubimg {
  width: 215% !important; 
}

.decorsubimg1 {
  width: 42%; background-size: cover; background-repeat: no-repeat; background-position: center center;
}


body::-webkit-scrollbar {
  width: 5px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /*outline: 1px solid slategrey;*/
}


@media (max-width: 1800px) {
  .main-image {
    width: 367px;
    height: 400px;
    transition: all 0.55s ease;
    zoom: 130%;
  }
  .decorsubimg1 {
    width: 367px;
    zoom: 130%;
    background-size: cover; background-repeat: no-repeat; background-position: center center;
  }
}

@media (max-width: 1600px) {
  .decorsubimg1 {
    width: 367px;
    zoom: 130%; 
    background-size: cover; background-repeat: no-repeat; background-position: center center;
  }
}

@media (max-width: 1450px) {
  .main-image {
    zoom: 130%;
  }
  .decortextbody {
    width: 50% !important;
    margin-left: 10% !important;
  }
  .decorsubimg1 {
    width: 367px;
    zoom: 130%; 
    background-size: cover; background-repeat: no-repeat; background-position: center center;
  }
}

@media (max-width: 1250px) {
  .main-image {
    zoom: 130%;
  }
  .decorsubimg1 {
    width: 367px;
    zoom: 130%;
    background-size: cover; background-repeat: no-repeat; background-position: center center;
  }
}

@media (max-width: 1000px) {
  /*
  .img-container-decor img {
    margin-top: -450px;
  }
  */
  .decortextbody {
    width: 50% !important;
    margin-left: 0% !important;
  }
  .decorsubimg1 {
    width: 367px;
    zoom: 130%;
    background-size: cover; background-repeat: no-repeat; background-position: center center;
    height: 120px
  }
}

.scintilla-text {
  font-size: 20px;
}

.decorslist-search-categories {
  width: 50%;
}

.animated-pointer-home {
  position: absolute; 
  top: 93%; 
  right: 48%;
}

.col-md-6.geo-col {

  margin-left: 10%;
}


.brandsdiv {
  width: 100% !important;
  height: 137px;
  /* border: 1px solid blue; */
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  /* box-shadow: 0px -32px 39px -38px rgb(0 0 0 / 12%), 0px 13px 20px -18px rgb(0 0 0 / 25%); */
}

.swiper-slide { 
  position: relative;
  height: auto;
}

.swiperbookh-dc p {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-45%, -50%);
  transform: translate(-45%, -50%);
  color: #000;
  font-size: 18px;
}

.video-banner {
  position: relative;
  height: auto;
}

.video-sub-banner {
  position: relative;
  height: auto;
}

.banner-text {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-45%, -50%);
  transform: translate(-45%, -50%);
  color: #343434;
  font-size: 40px;
}

.banner-braces {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-45%, -50%);
  transform: translate(-45%, -50%);
  color: #343434;
  font-size: 40px;
  zoom: 85%;
}

.angle1 {
  font-size: 20px;
  rotate: 225deg;
  margin-left: -30px;
  margin-top: 115px;
  font-weight: 700;
}

.angle2 {
    font-size: 20px;
    rotate: 45deg;
    margin-left: 300px;
    margin-bottom: 100px;
    font-weight: 700;
}

#color {
  transition: color 1.5s;
  color: black;
}

@media (max-width: 767px) {

  .banner-text-wrapper {
    zoom: 50%;
  }

  .angle1 {
    font-size: 20px;
    rotate: 225deg;
    margin-left: -30px;
    margin-top: 115px !important;
    font-weight: 700;
  }
  
  .angle2 {
      font-size: 20px;
      rotate: 45deg;
      margin-left: 300px;
      margin-top: 0px !important;
      margin-bottom: 100px;
      font-weight: 700;
  }

  .fa-angle-right2 {
    margin-top: 5px !important;
  }
  .swiperbookh-dc p {
    font-size: 17px;
  }
  .animated-pointer-home {
    position: absolute; 
    top: 100%; 
    right: 46%;
  }
  .logo img {
    width: 80px !important;
  }
  .home-sgloss {
    width: 22px;
  }
  .nav-tabs {
    display: none !important;
    text-align: left !important;
  }
  .fa-angle-left, .fa-angle-right {
    margin-top: 100px;
  }
  .dbl-main-sec {
      height: auto !important;
  }
  .dbl-sub-sec .dbl-sub-sec img {
    height: auto !important;
  }
  .decorslist-search-categories {
    width: 85%;
  }
  .decorslist-tiles {
    zoom: 46%;
  }
  .decore-tile-container {
    margin-bottom: 30px;
  }
  .scintilla-text {
    zoom: 60%;
  }
  .scinrilla-boxs {
    zoom: 60%;
  }
  .scinrilla-tiles {
    zoom: 40%;
  }
  .mainlogo {
    width: 60px !important;
    margin-left: 10px;
  }
  .main-image {
    zoom: 100%;
  }
  .decorsubimg {
    width: 265% !important; 
    margin-top: -30px;
  }
  .decorsubimg1 {
    width: 367px;
    zoom: 100%; 
    background-size: cover; background-repeat: no-repeat; background-position: center center;
  }
  .decortextbody {
    width: 100% !important;
    margin-left: 0% !important;
  }
  #product_image {
    /*zoom: 50%;*/
    /*margin-top: 100px;*/
    width: 600px !important;
    height: auto !important;
  }
  #produto {
    zoom: 60%;
  }
  .swiperbookh {
    margin-top: 180px;
  }
  .texthov {
    margin-top: 20px;
  }

  .carousel .control-prev.control-arrow::after {
    border-right: 0px solid #fff;
    content: '←'!important;
    margin-left: 0px !important;
  }

}

.hero-banner-mobile{
  display:none
}


.hieghtimg{
  height: 100%;
  width: 25% !important;
  
  }


@media only screen and (max-width: 767px) {
  

  .hieghtimg{
    height: 100%;
    width: 80% !important;
  }
  /* Homepage HEro Banner css  */

  .hero-banner-desktop{ 
  display:none;
  }

  .hero-banner-mobile{
Display:block;
  }

  .decortxtmobile{
    display: block !important;
    }
  
  .dectextmobdesk{

    display: none;
  }

  .decortextbody {
    width: 100%;
    height: 100%;
    padding-top: 3%;
  }

  .dectexthead{
    width: 100% !important;
    margin-left: 0% !important;
 
  } 
}

#produto {
  overflow: hidden; height: 900px; width: 600px;
}

@media (max-width: 768px){
  #produto {
    overflow: scroll; height: 900px; width: 600px;
    margin-top: 150px !important;
  }
  .zoomed {
    zoom: 150% !important;
  }
  #produto::-webkit-scrollbar{
    display: none;
  }
}

.decortxtmobile{
display:none;
}

.form-check-input:checked {
  background-color: #404041 !important;
  border-color: #404041 !important;
}

@media (max-width: 1250px) {
  .web {
    zoom: 80% !important;
  }
}

@media (max-width: 1000px) {
  .web {
    zoom: 60% !important;
  }
  .decorbody {
    zoom: 85%;
  }
  .decorbrandlogos img{
    width: 100px;
  }
  .decorbrandlogos {
    margin-left:  0px;
    width: 15% !important;
  }
}

.moodboard-home {
  width: 83% !important;
}

.bookslider-home {
  width: 87% !important;
padding:7px;
}

@media (max-width: 767px) {

  .bookslider-home {
    width: 80% !important;
  }

  .moodboard-home {
    width: 100% !important;
  }

  .decorbrandlogos img{
    width: 60px;
  }

  .web {
    zoom: 100% !important;
  }
  .abouttxtshadow {
    font-size: 14px;
    font-weight: bold;
    font-family: "CenturyGothic";
    color: #404041;
}
  .abouttxtbold {
    font-size: 14px;
    font-weight: bold;
    font-family: "CenturyGothic";
    color: #404041;
  }
  .abouttxtshadowbold {
    font-size: 14px;
    font-weight: bold;
    font-family: "CenturyGothic";
    color: #404041;
}
  .about-dash {
    display: none;
  }

  .catalog .fa-angle-left {
    position: absolute;
    top: 13%;
    padding-top: 15%;
    left: 5%;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
    color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100px;
    transition: all 0.55s ease;
  }
  
  .catalog .fa-angle-right {
    position: absolute;
    top: 13%;
    padding-top: 15%;
    right: 5%;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
    color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100px;
    transition: all 0.55s ease;
  }


  .dds-control .left, .dds-control2 .left2 {

    top: 35%;

  }
  
  .dds-control .right, .dds-control2 .right2 {
   
    top: 35%;
  
  }


}

@media (max-width: 592px) {
  .trending-sub-row div {
    padding: 0px 3px;
  }
  
  .trending-sub-row {
    margin-left: -26px !important;
    width: 105.5%;
    margin-top: -23px !important;
  }
  .trending-sub-row img {
    width: 105.5% !important;
  }
}

@media (max-width: 420px) {
  .trending-sub-row div {
    padding: 0px 3px;
  }
  
  .trending-sub-row {
    margin-left: -26px !important;
    width: 108.2%;
    margin-top: -23px !important;
  }
  .trending-sub-row img {
    width: 108.2% !important;
  }
}


.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 100% !important;
  width: calc(var(--swiper-navigation-size)/ 44 * 27);
  height: var(--swiper-navigation-size);
  /* margin-top: calc(0px - (var(--swiper-navigation-size)/ 2)); */
  margin-top: 27px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: var(--swiper-navigation-color,var(--swiper-theme-color)); */
  color: #000 !important;
}

.swiper-button-prev {
  left: 42% !important;
  right: auto;
}

.swiper-button-next {
  right: 42.3% !important;
  left: auto;
}



  .bookslider-home-wrapper {
    margin-top: 5.4rem !important;
  }



@media (max-width: 1700px) {

  .bookslider-home-wrapper {
    margin-top: 5.9rem !important;
  }

}

@media (max-width: 1450px) {

  .bookslider-home-wrapper {
    margin-top: 6.2rem !important;
  }

}

@media (max-width: 592px) {

  
  .bookslider-home-wrapper {
    margin-top: 6rem !important;
  }

  .swiper-button-next, .swiper-button-prev {
    margin-top: 7px !important;
  }

  .swiper-button-prev {
    left: 42.5% !important;
    right: auto;
  }
  
  .swiper-button-next {
    right: 40% !important;
    left: auto;
  }

}

.scintilla #main-video {
  width: 80%;
  height: auto;
  padding-left: 10px;
  padding-right: 5px;
}

.scintilla .video-title-wrapper-mobile {
  display: none;
}

.scintilla .video-title-wrapper-desktop {
  display: block;
}

@media (max-width: 592px) {
  .scintilla .centered-div {
    margin: 0px !important;
  }
  .scintilla #main-video {
    width: 130%;
    height: auto;
    margin-left: -35px;
    padding-left: 0px;
    padding-right: 0px;
    transform: rotate(90deg);
  }
  .scintilla .dds-control .left {
    margin-left: 40px;
    color: #fff;
    top: 38%;
  }
  .scintilla .dds-control .right {
    margin-right: 40px;
    color: #fff;
    top: 38%;
  }
  .scintilla .img-container {
    width: 80% !important;
    padding-top: 100px !important;
  }
  .scintilla .video-title-wrapper-mobile {
    display: block;
    height: 50px !important;
    position: absolute;
    left: 0%;
    top: 16.5%;
  }
  .scintilla .video-title-wrapper-mobile .video-title {
    font-size: 15px !important;
  }
  .scintilla .video-title-wrapper-desktop {
    display: none;
  }
}

.decor-library-home {
  height: 600px;
  background-image: url('/public/images/decorslib.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.dl-text-wrap {
  margin-top: -200px;
}

.texthov {
  position: absolute !important;
  left: 20px !important;
  width: 90% !important;
  height: auto !important;
}

.decolibhtwo-1 {
  color: #4b4b4b;
  margin-top: 120px;
  transition: 0.5s;
  font-size: 22px;
  margin-left: 9.1%;
  font-weight: 350;
  text-align: left;
}

@media (max-width: 767px) {
 
 
 .container-fluid-gdc{
width: 93%;

 }
 
 
  .col-md-6.geo-col {
    margin-left: -2%;
}


  .decor-library-home { 
    zoom: 50%;
    height: 400px;
  }

  .dl-text-wrap {
    margin-top: -200px;
  }
  
  .texthov {
    position: absolute !important;
    left: 20px !important;
    width: 90% !important;
    height: auto !important;
  }
  
  .decolibhtwo-1 {
    color: #4b4b4b;
    margin-top: 120px;
    transition: 0.5s;
    font-size: 22px;
    margin-left: 9.1%;
    font-weight: 350;
    text-align: left;
  }

}

.isDisabled {
  pointer-events: auto; /* Allows default behaviour */
}

.m-product, .r-product {
  width: 133px !important;
  height: 120px !important;
  margin-right: 5px !important;
  overflow: hidden !important;
  border-radius: 10px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.m-image, .r-image {
  width: 100% !important;
  height: auto !important;
}

.main-image, .decorsubimg1 {
  cursor: pointer;
}

@media (max-width: 592px) {
  .m-product, .r-product {
    width: 58px !important;
    height: 52px !important;
    margin-right: 5px !important;
    overflow: hidden !important;
    border-radius: 10px !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  }
  .dl-text-wrap {
    margin-top: -250px;
  }
  .isDisabled {
    pointer-events: none; /* Disables the button completely. Better than just cursor: default; */
  }
}

.m-product, .r-product {
  transition: 0.5s;
}

.m-product:hover, .r-product:hover {
  scale: 0.9;
}

.trending-sub-row img {
  transition: 0.5s;
}

.trending-sub-row img:hover {
  scale: 1.1;
}

.pointer{
  width:12px;
  height:12px;
  display:inline-block;
  border-radius:50%;
  color:#fff;
  background:#fff;
  position:relative;
  -ms-transform:none;
  transform:none
}
.pointer:after{
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  background:currentColor;
  animation:pulse 2s infinite;
  opacity:1;
  border-radius:50%;
  top:0;
  left:0
}
@media (prefers-reduced-motion){
  .pointer:after{
      animation:none !important
  }
}
.pointer.is-big{
  width:60px;
  height:60px
}
.pointer.is-big:after{
  animation:pulseBig 2s infinite
}
@media (prefers-reduced-motion){
  .pointer.is-big:after{
      animation:none !important
  }
}
.compact .pointer.is-big{
  width:30px;
  height:30px
}
.pointer.is-error{
  color:#df484a;
  background:#df484a
}
.pointer.is-warning{
  color:#f29030;
  background:#f29030
}
.pointer.is-grey{
  color:#637189;
  background:#637189
}
.pointer.is-grey:after{
  display:none
}
@keyframes pulseBig{
  0%{
      opacity:0.5
  }
  70%{
      opacity:0;
      transform:scale(1.6)
  }
  100%{
      opacity:0
  }
}
@keyframes pulse{
  0%{
      opacity:0.5
  }
  70%{
      opacity:0;
      transform:scale(2.5)
  }
  100%{
      opacity:0
  }
}

.decorLibraryWrapper {
  min-height: 900px;
}

.catalog .fa-angle-left, .catalog .fa-angle-right {
  color: rgba(0,0,0,0.3) !important;
}

.catalog .fa-angle-left:hover {
  color: rgba(0,0,0,1) !important;
}

.catalog .fa-angle-right:hover {
  color: rgba(0,0,0,1) !important;
}

#afsanalytics {
  display: none !important;
}

.tactile {
  cursor: pointer;
  transition: 0.5s;
}

.angle {
  rotate: 90deg;
}

.invert {
  filter: invert(100%);
}